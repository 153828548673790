module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"custom":[{"name":["Font Awesome 5 Brands","Font Awesome 5 Free"],"file":"/fonts/fontAwesome/css/all.min.css"}],"web":[{"name":"Staatliches","file":"https://fonts.googleapis.com/css2?family=Staatliches"},{"name":"Spicy Rice","file":"https://fonts.googleapis.com/css2?family=Spicy+Rice"},{"name":"Afacad Flux","file":"https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap"},{"name":"Barrio","file":"https://fonts.googleapis.com/css2?family=Galindo&display=swap"},{"name":"Quantico","file":"https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap"},{"name":"Gaegu","file":"https://fonts.googleapis.com/css2?family=Gaegu&family=Quicksand:wght@300..700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
